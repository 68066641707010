<template>
  <div class="index">
    <div class="wrap" v-if="windowWidth">
      <div class="introduce">
        <p class="title">
          <span class="center">About Tencent ARC</span>
        </p>
        <p class="content">
          <span
            >Tencent ARC Lab explores the cutting-edge
            technologies of intelligent media</span
          >
        </p>
      </div>
      <div class="hope">
        <div class="hope_left">
          <p class="title">
            <span>Our Mission</span>
          </p>
          <p class="content">
            <span
              >Applied Research Center (ARC) develops the cutting-edge
              technology of intelligent media, and aims to be a world-class
              research center. ARC empowers Tencent PCG, a large business group
              running Tencent social media, information feed, search, and
              content platforms, which require almost all the technics regarding
              artificial intelligence and big data. Benefited from these
              demands, ARC advantages in research breadth, depth, and industry
              integration.
            </span>
          </p>
          <p class="line">
            <span></span>
          </p>
        </div>
        <div class="hope_right">
          <img src="../../../assets/img/hope_right.png" alt="" />
        </div>
      </div>
      <div class="team">
        <div class="team_left">
          <img src="../../../assets/img/team_left.png" alt="" />
        </div>
        <div class="team_right">
          <p class="title">
            <span>Team Introduction</span>
          </p>
          <p class="content">
            <span
              >Since its establishment in early 2019, ARC has attracted a number
              of talents from top universities and international companies, who
              have published top academic articles as well as international
              patents. Through university cooperation, internship, innovation
              project, etc., ARC has also gathered a group of outstanding
              interns. ARC aims to recruit the best researchers to do the
              frontier and applicable researches.
            </span>
          </p>
          <p class="line">
            <span></span>
          </p>
        </div>
      </div>
      <div class="field">
        <div class="field_left">
          <p class="title">
            <span>Mechanisms and Areas</span>
          </p>
          <p class="content">
            <span
              >ARC mainly focuses on areas of computer vision, speech, and
              natural language processing, which include speech/video
              generation, enhancement, retrieval, understanding, AutoML, etc.
              Considering project needs and industry trends, ARC consistently
              pursues exploration, innovation, and breakthroughs in
              technologies.
            </span>
          </p>
          <p class="line">
            <span></span>
          </p>
        </div>
        <div class="field_right">
          <img src="../../../assets/img/field_right.png" alt="" />
        </div>
      </div>
    </div>
    <div :class="windowWidth?'demo_link':'demo_linkMini'" @mouseover="over_hover()" @mouseout="out_hover()" >
      <router-link :to="'/en/ai-demos/faceRestoration'">
      <p class="toDemo">Demo</p>
      <img v-show="!demo_hover" src="../../../assets/img/Group 1489.png" alt="" />
      <img v-show="demo_hover" src="../../../assets/img/Group 1496.png" alt="" title="demo体验" />
      </router-link>
    </div>
    <div class="wrap_mini" v-if="windowWidth == false">
      <div class="introduce">
        <p class="title">
          <span class="center">About Tencent ARC</span>
        </p>
        <p class="content">
          <span
            >Tencent ARC Lab explores the cutting-edge
            technologies of intelligent media</span
          >
        </p>
      </div>
      <div class="hope">
        <div class="hope_right">
          <img src="../../../assets/img/hope_right.png" alt="" />
        </div>
        <div class="hope_left">
          <p class="title">
            <span>Our Mission</span>
          </p>
          <p class="content">
            <span
              >Applied Research Center (ARC) develops the cutting-edge
              technology of intelligent media, and aims to be a world-class
              research center. ARC empowers Tencent PCG, a large business group
              running Tencent social media, information feed, search, and
              content platforms, which require almost all the technics regarding
              artificial intelligence and big data. Benefited from these
              demands, ARC advantages in research breadth, depth, and industry
              integration.
            </span>
          </p>
          <p class="line">
            <span></span>
          </p>
        </div>
      </div>
      <div class="team">
        <div class="team_left">
          <img src="../../../assets/img/team_left.png" alt="" />
        </div>
        <div class="team_right">
          <p class="title">
            <span>Team Introduction</span>
          </p>
          <p class="content">
            <span
              >Since its establishment in early 2019, ARC has attracted a number
              of talents from top universities and international companies, who
              have published top academic articles as well as international
              patents. Through university cooperation, internship, innovation
              project, etc., ARC has also gathered a group of outstanding
              interns. ARC aims to recruit the best researchers to do the
              frontier and applicable researches.
            </span>
          </p>
          <p class="line">
            <span></span>
          </p>
        </div>
      </div>
      <div class="field">
        <div class="field_right">
          <img src="../../../assets/img/field_right.png" alt="" />
        </div>
        <div class="field_left">
          <p class="title">
            <span>Mechanisms and Areas</span>
          </p>
          <p class="content">
            <span
              >ARC mainly focuses on areas of computer vision, speech, and
              natural language processing, which include speech/video
              generation, enhancement, retrieval, understanding, AutoML, etc.
              Considering project needs and industry trends, ARC consistently
              pursues exploration, innovation, and breakthroughs in
              technologies.
            </span>
          </p>
          <p class="line">
            <span></span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "../../../assets/js/eventBus";
export default {
  // 监听页面宽度
  created() {
    window.addEventListener("resize", this.getWidth);
    this.getWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.getWidth);
  },
  mounted() {
    bus.$emit("sendBybus", this.bannerHeight);
  },
  data() {
    return {
      bannerHeight: 600,
      windowWidth: true,
      demo_hover:false
    };
  },
  methods: {
    // 获取页面宽度
    getWidth() {
      if (window.innerWidth > 1032) {
        this.windowWidth = true;
      } else {
        this.windowWidth = false;
      }
    },
    over_hover(){
      this.demo_hover = true;
    },
    out_hover(){
      this.demo_hover = false;
    },
  },
};
</script>

<style lang="less" scoped>
.index {
  width: 100%;
  font-size: 0.16rem;
  overflow: hidden;
  .wrap {
    max-width: 1200px;
    margin: 0 auto;
    .introduce {
      margin-top: 0.6rem;
      .title {
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
        margin-bottom: 0.15rem;
        .center {
          position: relative;
          display: inline-block;
          font-family: PingFangSC-Semibold, sans-serif;
          font-size: 0.36rem;
          color: #222;
        }
      }
      .content {
        text-align: center;
        span {
          display: inline-block;
          width: 5.71rem;
          min-height: 0.64rem;
          font-family: PingFangSC-Regular, sans-serif;
          font-size: 0.16rem;
          color: #666666;
          line-height: 0.32rem;
          text-align: center;
        }
      }
    }
    // 模块共用样式
    .hope,
    .team,
    .field {
      display: inline-block;
      margin-top: 0.6rem;
      margin-left: 0.45rem;
    }
    .hope_left,
    .hope_right,
    .team_left,
    .team_right,
    .field_left,
    .field_right {
      float: left;
    }
    .hope_right,
    .team_left,
    .field_right {
      width: 4.2rem;
      height: 4.2rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .hope_left,
    .team_right,
    .field_left {
      width: 5.9rem;
      .title {
        margin-top: 0.45rem;
        span {
          display: inline-block;
          height: 0.5rem;
          line-height: 0.5rem;
          font-family: PingFangSC-Semibold, sans-serif;
          font-size: 0.36rem;
          color: #222222;
        }
      }
      .content {
        margin-top: 0.15rem;
        span {
          display: inline-block;
          width: 5.9rem;
          font-family: PingFangSC-Regular, sans-serif;
          font-size: 0.16rem;
          color: #666666;
          line-height: 0.32rem;
        }
      }
      .line {
        margin-top: 0.4rem;
        span {
          display: inline-block;
          width: 0.5rem;
          height: 0.02rem;
          background: #000000;
        }
      }
    }
    // 模块独有样式
    .hope_left,.team_left,
    .field_left {
      margin-right: 1rem;
    }
    .field {
      margin-bottom: 0.6rem;
    }
  }
  .demo_link,.demo_linkMini {
    width: 1.2rem;
    height: 1.2rem;
    display: flex;
    position: fixed;
    right: 0.08rem;
    top: 5.41rem;
    z-index: 1000;
    img {
      width: 1.2rem;
      height: 1.2rem;
    }
    .toDemo{
      width: 100%;
      height: 0.22rem;
      position: absolute;
      top: .6rem;
      left: 0;
      color: #FFF;
      font-size: 0.16rem;
      line-height: 0.22rem;
      font-family: PingFang SC;
      font-weight: 500;
      text-align: center;
    }
  }
  .demo_linkMini{
    z-index: 101;
    top: 6.6rem;
  }
  .wrap_mini {
    .introduce {
      margin-top: 0.8rem;
      .title {
        line-height: 0.67rem;
        font-family: PingFangSC-Semibold, PingFangSC-Semibold, sans-serif;
        font-size: 0.48rem;
        color: #222222;
        text-align: center;
      }
      .content {
        text-align: center;
        margin-top: 0.22rem;
        span {
          display: inline-block;
          width: 5.67rem;
          font-family: PingFangSC-Regular, sans-serif;
          font-size: 0.28rem;
          color: #666666;
          text-align: center;
          line-height: 0.52rem;
        }
      }
    }
    .hope,.team,.field {
      margin: 1rem 0.69rem 0 0.91rem;
      .hope_right,.team_left,.field_right {
        width: 5.9rem;
        height: 5.9rem;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .hope_left,.team_right,.field_left {
        width: 5.9rem;
        margin: 0 auto;
        .title {
          margin: 0.6rem 0 0.4rem;
          span {
            line-height: 0.67rem;
            font-family: PingFangSC-Semibold, sans-serif;
            font-size: 0.48rem;
            color: #222222;
            text-align: center;
          }
        }
        .content {
          width: 5.9rem;
          font-family: PingFangSC-Regular,sans-serif;
          font-size: 0.28rem;
          color: #666666;
          line-height: 0.52rem;
        }
      }
    }
    .field{
      margin-bottom: 1rem;
    }
  }
}
</style>